<template>
  <div
      :class="[
      'inline-flex items-center justify-center w-6 h-6 text-xs font-bold border-2 rounded-full',
      bgColor,
      borderColor,
      positionClass
    ]"
  >
    <slot>
      {{ number }}
    </slot>
  </div>
</template>

<script setup lang="ts">
// Define the props with TypeScript types
const props = defineProps({
  number: {
    type: Number,
    required: false,
  },
  color: {
    type: String,
    default: 'red-500'
  },
  /**
   * 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left'
   */
  position: {
    type: String,
    default: 'top-right'
  }
})

// Set the default color if not provided
const bgColor = `bg-${props.color} text-white`;
const borderColor = 'border-white dark:border-gray-900';

// Compute the position class based on the position prop
const positionClass = computed(() => {
  switch (props.position) {
    case 'top-right':
      return 'absolute -top-2 -end-2';
    case 'bottom-right':
      return 'absolute -bottom-2 -end-2';
    case 'top-left':
      return 'absolute -top-2 -start-2';
    case 'bottom-left':
      return 'absolute -bottom-2 -start-2';
    default:
      return 'absolute -top-2 -start-2'; // Default position is top-left
  }
});
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
